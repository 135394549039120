import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BACKEND_URL } from '../../constants';
import { getAccessToken } from '../../service/auth';

export enum MailCodeTypes {
  authorization = 'authorization',
  registration = 'registration',
  changeMail = 'changeMail',
  addEmail = 'addEmail',
}

export const mailApi = createApi({
  reducerPath: 'mailApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BACKEND_URL}/api/mail/v1`,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      headers.set('authorization', `Bearer ${accessToken}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    checkEmailAvialability: builder.query<{ isAvailable: boolean; nickname?: string }, string>({
      query: (email) => `/check?email=${email}`,
    }),

    sendEmailCode: builder.mutation<
      { message?: string },
      {
        uid?: string;
        email: string;
        type: MailCodeTypes;
        userId?: string;
        name?: string;
        resend?: boolean;
        rebind?: boolean;
      }
    >({
      query: ({ uid, email, type, userId, name, resend, rebind }) => {
        let body = 'code_type=' + type + '&email=' + email + '&uid=' + uid;
        if (userId) body += '&user_id=' + userId;
        if (name) body += '&name=' + name;
        if (resend) body += '&resend=' + resend;
        if (rebind) body += '&rebind=' + rebind;
        const timezoneOffsetInHours = new Date().getTimezoneOffset() / 60;
        body += '&timezone_offset=' + timezoneOffsetInHours;

        return {
          url: 'confirmation_code',
          body,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded ',
          },
          method: 'POST',
        };
      },
    }),
  }),
});

export const { useLazyCheckEmailAvialabilityQuery, useSendEmailCodeMutation } = mailApi;
