import React from 'react';
import Select, { SelectProps } from '@mui/material/Select';
import { ReactComponent as ArrowDropDownIcon } from '../../icons/ArrowDropDown.svg';
import styles from './CustomSelect.module.css';
import clsx from 'clsx';

export const CustomSelect: <T>(props: Omit<SelectProps<T>, 'variant'>) => JSX.Element = ({
  className,
  ...props
}) => {
  return (
    <Select
      IconComponent={ArrowDropDownIcon}
      MenuProps={{ classes: { paper: styles.menu } }}
      className={clsx(className, styles.select)}
      classes={{ outlined: styles.outlined }}
      sx={{
        '&.Mui-focused fieldset': {
          borderColor: '#606783 !important',
        },
        '&.Mui-error fieldset': {
          borderColor: '#FC545C !important',
        },
      }}
      {...props}
    />
  );
};
