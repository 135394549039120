import Button from '@mui/material/Button';
import React, { ChangeEvent, FC, ReactElement, useEffect, useRef, useState } from 'react';
import styles from './UploadAndDisplayImage.module.css';
import { getImageURL, toBase64 } from '../helpers';
import ReactCrop, { Crop, PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useDebounceEffect } from '../hooks/useDebounceEffect';

type TUploadCroppedImageProps = {
  setAvatarError: (error: string) => void;
  setAvatarValue: (value: File | null) => void;
  clearAvatarError: () => void;
  defaultValue: string | null;
  setImgSrc: (value: string | null) => void;
  maxImageSize?: number;
  componentName?: string;
  DefaultIcon?: ReactElement;
  overRideImageSrc?: File | string | null;
  pathToAvatar?: string | null;
  setAvatarLink?: (value: string) => void;
  imgSrc?: string | null;
  disabled?: boolean;
  error?: string;
};

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

export const UploadCroppedImage: FC<TUploadCroppedImageProps> = ({
  setAvatarError,
  defaultValue,
  setAvatarValue,
  clearAvatarError,
  componentName,
  overRideImageSrc,
  pathToAvatar,
  setAvatarLink,
  imgSrc,
  setImgSrc,
  disabled,
  maxImageSize = 1,
  error,
}) => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const blobUrlRef = useRef('');
  const [fileType, setFileType] = useState('');
  const [crop, setCrop] = useState<Crop>();
  const [cropModalOpen, setCropModalOpen] = useState(false);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [cropImageSrc, setCropImageSrc] = useState('');
  const aspect = 20 / 9;

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        const image = imgRef.current;
        const previewCanvas = previewCanvasRef.current;
        if (!image || !previewCanvas || !completedCrop) {
          return;
        }

        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1, 0);
      }
    },
    100,
    [completedCrop, 1, 0],
  );

  useEffect(() => {
    if (typeof pathToAvatar !== 'string') pathToAvatar = null;
    setImgSrc(pathToAvatar);
  }, [pathToAvatar]);

  useEffect(() => {
    if (overRideImageSrc) {
      const setImgSrcAsync = async () => {
        if (overRideImageSrc instanceof Blob) {
          setImgSrc(await toBase64(overRideImageSrc));
        } else {
          setImgSrc(overRideImageSrc);
        }
      };

      setImgSrcAsync();
    }
  }, [overRideImageSrc]);

  useEffect(() => {
    if (defaultValue) {
      setImgSrc(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className={styles.container}>
      <Modal open={cropModalOpen}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: 'rgba(0, 0, 0, 0.7)',
            margin: 'auto',
            width: '100%',
            height: '100vh',
            justifyContent: 'flex-end',
            position: 'relative',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          >
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => {
                setCompletedCrop(c);
              }}
              aspect={aspect}
              minHeight={100}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={cropImageSrc}
                style={{
                  maxWidth: '100%',
                  display: 'block',
                  maxHeight: '60vh',
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
            {
              <Typography
                style={{ height: 40, visibility: error ? 'visible' : 'hidden' }}
                className={clsx('text-14', 'color-FC545C')}
              >
                {error}
              </Typography>
            }
          </div>
          <Button
            variant="custom"
            onClick={async () => {
              const image = imgRef.current;
              const previewCanvas = previewCanvasRef.current;
              if (!image || !previewCanvas || !completedCrop) {
                return;
              }
              const scaleX = image.naturalWidth / image.width;
              const finalWidth = Math.min(1920, completedCrop.width * scaleX);
              const finalHeight = finalWidth / aspect;
              const offscreen = new OffscreenCanvas(finalWidth, finalHeight);
              const ctx = offscreen.getContext('2d');
              if (!ctx) {
                return;
              }

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (ctx as any).drawImage(
                previewCanvas,
                0,
                0,
                previewCanvas.width,
                previewCanvas.height,
                0,
                0,
                offscreen.width,
                offscreen.height,
              );

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const blob = await (offscreen as any).convertToBlob({
                type: fileType,
              });

              if (blobUrlRef.current) {
                URL.revokeObjectURL(blobUrlRef.current);
              }
              blobUrlRef.current = URL.createObjectURL(blob);
              const file = new File([blob], 'name.' + fileType.split('/')[1], {
                type: fileType,
              });
              const src = await toBase64(file);

              if (file.size / 1024 / 1024 > maxImageSize)
                return setAvatarError(`Размер изображения не должен превышать ${maxImageSize} МБ`);

              setImgSrc(src);
              setAvatarValue(file);
              clearAvatarError();
              setAvatarLink?.('');
              setCropModalOpen(false);
            }}
          >
            Применить
          </Button>
          <Button
            variant="custom"
            color="secondary"
            onClick={async () => {
              setCropImageSrc('');
              clearAvatarError();
              setAvatarLink?.('');
              setCropModalOpen(false);
              setFileType('');
            }}
          >
            Отменить
          </Button>
        </div>
      </Modal>

      <div className={styles['flex-container']}>
        {!cropImageSrc && !!imgSrc && (
          <div
            style={{
              backgroundImage: `url(${imgSrc?.startsWith('data:') ? imgSrc : getImageURL(imgSrc)})`,
              width: (40 * 20) / 9,
            }}
            className={styles['app-icon']}
          />
        )}
        {!!completedCrop && !!cropImageSrc && (
          <canvas
            ref={previewCanvasRef}
            className={styles['app-icon']}
            style={{
              objectFit: 'contain',
              width: 48 * aspect,
              height: 48,
              marginRight: 16,
            }}
          />
        )}
        {imgSrc && !disabled && (
          <Button
            onClick={() => {
              setCrop(undefined);
              setFileType('');
              setImgSrc(null);
              setCropImageSrc('');
              setAvatarValue(null);
              clearAvatarError();
              setAvatarLink?.('');
            }}
            className={styles['delete-button']}
            variant="custom"
            color="secondary"
          >
            Удалить
          </Button>
        )}
        <Button variant="custom" component="label" color="secondary" disabled={disabled}>
          Загрузить
          <input
            id={componentName}
            type="file"
            name="myImage"
            hidden
            onChange={async (event: ChangeEvent<HTMLInputElement>) => {
              setCrop(undefined);
              const file = event.target.files?.[0];
              if (file) {
                if (
                  !['jpeg', 'png', 'jpg'].find(
                    (imageType) => `image/${imageType}` === file.type,
                  )
                )
                  return setAvatarError('Недопустимый формат изображения');
                const src = await toBase64(file);
                setFileType(file.type);
                setCropImageSrc(src);
                event.target.value = '';
                clearAvatarError();
                setAvatarLink?.('');
                setCropModalOpen(true);
              }
            }}
          />
        </Button>
      </div>
    </div>
  );
};
