import React, { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from '../redux/store';
import { setIsNotificationPanelOpen, TAppSlice } from '../redux/appSlice';
import { deleteAllNotices, setIsReadNotice, TNotice } from '../redux/noticesSlice';
import clsx from 'clsx';
import styles from './NotificationPanel.module.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ReactComponent as GoOverIcon } from '../icons/GoOver.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as BroomIcon } from '../icons/Broom.svg';
import { Notice } from './Notice';
import { Link } from 'react-router-dom';

type TNotificationPanelProps = {
  isNotificationPanelOpen: TAppSlice['isNotificationPanelOpen'];
  notices: TNotice[];
};

const mapStateToProps = (state: RootState) => ({
  isNotificationPanelOpen: state.app.isNotificationPanelOpen,
  notices: state.notices.notices,
});

const NotificationPanelComponent: FC<TNotificationPanelProps> = ({
  isNotificationPanelOpen,
  notices,
}) => {
  const newNotices = notices?.filter((notice) => notice.isRead === false);
  const dispatch = useDispatch();
  const closeDrawer = () => {
    dispatch(setIsNotificationPanelOpen(false));
    dispatch(setIsReadNotice(true));
  };
  const removeAllNotices = () => dispatch(deleteAllNotices());

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      open={isNotificationPanelOpen}
      anchor="right"
      onClose={closeDrawer}
      BackdropProps={{ invisible: true }}
    >
      <Box className={styles.wrapper}>
        <Toolbar className={styles.bar}>
          <div className={styles['section-count']}>
            <Typography
              className={clsx(
                'text-17-regular',
                'color-0B1641',
                'font-golos',
                styles['margin-right'],
              )}
            >
              Уведомления
            </Typography>
            <Typography className={clsx('text-17-regular', 'color-B6BAC6', 'font-golos')}>
              {newNotices?.length || ''}
            </Typography>
          </div>
          <div className={styles['section-buttons']}>
            <Tooltip
              placement="bottom-end"
              arrow
              title={'Очистить'}
              classes={{
                tooltip: styles.tooltip,
                arrow: styles['tooltip-arrow'],
              }}
            >
              {notices?.length ? (
                <IconButton className={styles.button} onClick={removeAllNotices}>
                  <BroomIcon style={{ margin: 0 }} />
                </IconButton>
              ) : (
                <IconButton className={styles['not-active']}>
                  <BroomIcon style={{ margin: 0 }} />
                </IconButton>
              )}
            </Tooltip>
            <Tooltip
              placement="bottom-end"
              arrow
              title={'Перейти в журнал'}
              classes={{
                tooltip: styles.tooltip,
                arrow: styles['tooltip-arrow'],
              }}
            >
              <Link to={`/profile/event-log`}>
                <IconButton className={styles.button} onClick={closeDrawer}>
                  <GoOverIcon style={{ margin: 0 }} />
                </IconButton>
              </Link>
            </Tooltip>
            <IconButton className={styles['close-button']} onClick={closeDrawer}>
              <CloseIcon />
            </IconButton>
          </div>
        </Toolbar>
        <div className={styles.notices} data-simplebar>
          {notices?.map((notice) => (
            <Notice
              message={notice.message}
              timestamp={notice.timestamp}
              id={notice.id}
              key={notice.id}
              isRead={notice.isRead}
            />
          ))}
        </div>
      </Box>
    </Drawer>
  );
};

export const NotificationPanel = connect(mapStateToProps)(NotificationPanelComponent);
