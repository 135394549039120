import React, { useState } from 'react';
import { Navigate, Outlet, RouteProps, useLocation } from 'react-router-dom-v5-compat';
import { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { getAccessToken } from '../../service/auth';
import { checkAccessToken } from '../../requests/oidc';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import { TUserProfile } from '../../redux/userSlice';
import { isOwner, showDisplay } from '../../helpers';
import { Roles } from '../../enums';
import { TopTabsApplication } from '../applications/TopTabsApplication';

type OwnerRouteProps = RouteProps & {
  userId: TUserProfile['id'];
  email: TUserProfile['email'];
  passwordChangeRequired: TUserProfile['password_change_required'];
  birthdate: TUserProfile['birthdate'];
  userRole?: Roles;
  isUserProfileLoading: boolean;
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  email: state.user.userProfile.email,
  passwordChangeRequired: state.user.userProfile.password_change_required,
  birthdate: state.user.userProfile.birthdate,
  userRole: state.user.userProfile.role,
  isUserProfileLoading: state.user.isUserProfileLoading,
});

const OwnerRouteComponent: React.FC<OwnerRouteProps> = ({
  userId,
  email,
  passwordChangeRequired,
  birthdate,
  userRole,
  isUserProfileLoading,
}) => {
  const [wait, setWait] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [roleIsOwner, setRoleIsOwner] = useState(false);
  const location = useLocation();
  const redirectToFillProfile =
    location.pathname !== '/fill-profile' &&
    userId &&
    (passwordChangeRequired || !email || !birthdate);

  useEffect(() => {
    let cleanupFunction = false;

    const authorization = async () => {
      try {
        const token = await getAccessToken();
        if (token) {
          const checkResult = await checkAccessToken(token);
          !cleanupFunction && setIsAuthorized(checkResult);
        } else {
          !cleanupFunction && setIsAuthorized(false);
        }

        if (userRole) {
          if (isOwner(userRole)) {
            !cleanupFunction && setRoleIsOwner(true);
            !cleanupFunction && showDisplay();
          } else {
            !cleanupFunction && setRoleIsOwner(false);
          }
        }
        if (!isUserProfileLoading) !cleanupFunction && setWait(false);
      } catch (e) {
        console.log('auth error: ' + e);
      }
    };
    authorization();

    return () => {
      cleanupFunction = true;
    };
  }, [location.pathname, isUserProfileLoading]);

  const OwnerOutlet = () => {
    if (isAuthorized && roleIsOwner) {
      if (redirectToFillProfile) return <Navigate to="/fill-profile" replace />;
      return (
        <>
          <TopTabsApplication />
          <Outlet />
        </>
      );
    }
    return <Navigate to={'/applications'} replace />;
  };

  return wait ? <LinearProgress /> : <OwnerOutlet />;
};

export const OwnerRoute = connect(mapStateToProps)(OwnerRouteComponent);
