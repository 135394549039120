import React, { FC, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import styles from './LicenseModal.module.css';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ReactComponent as CopyIcon } from '../../icons/Copy.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { useActivateLicenseMutation, useGetLicensesQuery } from '../../redux/services/owner';
import clsx from 'clsx';

type TLicenseModal = {
  isOpen: boolean;
  close: () => void;
};

export const LicenseModal: FC<TLicenseModal> = ({ isOpen, close }) => {
  const { data: licenses, isFetching: licensesFetching } = useGetLicensesQuery();
  const [activateLicense] = useActivateLicenseMutation();
  const [license, setLicense] = useState('');
  const hasActiveLicense = !licensesFetching && licenses?.length;

  return (
    <div>
      <Drawer onClose={close} open={isOpen} anchor="right" variant="temporary">
        <div className={styles.wrapper}>
          <div className={styles.header}>
            {/* #807 <Typography className={clsx('text-24-medium', 'font-golos', 'color-0B1641')}>
              Добавить лицензию
            </Typography> */}
            <Typography className={clsx('text-24-medium', 'font-golos', 'color-0B1641')}>
              {!hasActiveLicense ? 'Активировать лицензию' : 'Лицензия активна'}
            </Typography>
            <IconButton onClick={close} className={styles['close-button']}>
              <CloseIcon />
            </IconButton>
          </div>
          {!hasActiveLicense && (
            <TextField
              name="license"
              className={clsx('custom', styles.textField)}
              fullWidth
              variant="standard"
              placeholder="Введите лицензию"
              value={license}
              onChange={(event) => setLicense(event.target.value)}
              InputProps={{
                classes: {
                  input: styles.input,
                },
                endAdornment: (
                  <Button
                    className={styles.activateButton}
                    variant="custom2"
                    disabled={!license}
                    onClick={async () => {
                      await activateLicense({ license });
                      setLicense('');
                    }}
                  >
                    Активировать
                  </Button>
                ),
              }}
            />
          )}
          {!licensesFetching &&
            licenses?.map((license) => (
              <div className={styles.licenseWrapper} key={license}>
                <Typography className={clsx('text-14', 'color-0B1641', styles.license)}>
                  {license}
                </Typography>
                <Tooltip
                  placement="bottom-end"
                  arrow
                  title={'Копировать лицензию'}
                  classes={{
                    tooltip: styles['input-tooltip'],
                    arrow: styles['input-tooltip-arrow'],
                  }}
                >
                  <IconButton
                    onClick={async () => {
                      await navigator.clipboard.writeText(license);
                    }}
                    className={styles['license-button']}
                  >
                    <CopyIcon />
                  </IconButton>
                </Tooltip>
              </div>
            ))}
        </div>
      </Drawer>
    </div>
  );
};
