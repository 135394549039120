import styles from './Applications.module.css';
import React, { FC, MouseEvent, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { ReactComponent as ArrowDown } from '../../icons/ArrowDown.svg';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as AddIcon } from '../../icons/Add.svg';
import clsx from 'clsx';
import { RootState } from '../../redux/store';
import { connect, useDispatch } from 'react-redux';
import { isAdministrator } from '../../helpers';
import { ApplicationsTable } from './ApplicationsTable';
import { ApplicationRightPanel } from './ApplicationRightPanel';
import { SelectViewType } from '../custom/SelectViewType';
import { setClientPanel, TAppSlice } from '../../redux/appSlice';
import { useNavigate, useLocation } from 'react-router-dom-v5-compat';

type TApplicationsProps = {
  userId?: string;
  userRole?: string;
  isClientPanelOpen: TAppSlice['isClientPanelOpen'];
  isMobile: TAppSlice['isMobile'];
};

const mapStateToProps = (state: RootState) => ({
  userId: state.user.userProfile.id,
  userRole: state.user.userProfile.role,
  isClientPanelOpen: state.app.isClientPanelOpen,
  isMobile: state.app.isMobile,
});

const ApplicationsComponent: FC<TApplicationsProps> = ({
  userRole,
  isClientPanelOpen,
  isMobile,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirectString = params.get('redirect'); // bar
  const [viewAnchorEl, setViewAnchorEl] = useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setIsPanelOpen = (isOpen: boolean) => dispatch(setClientPanel(isOpen));

  const setPanelView = (e: MouseEvent<HTMLLabelElement | SVGSVGElement>, isOpen: boolean) => {
    e.stopPropagation();
    setIsPanelOpen(isOpen);
    setViewAnchorEl(null);
  };
  return (
    <Box className={styles.applications}>
      {redirectString && (
        <Button
          onClick={() => navigate(redirectString)}
          variant="custom3"
          className={clsx('text-15', styles['button-back'])}
          startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
        >
          Назад
        </Button>
      )}
      <Box className={styles['applications-top']}>
        <Typography className={clsx('font-golos', 'text-24-medium', 'color-0B1641')}>
          Приложения
        </Typography>
        {isAdministrator(userRole) && (
          <Button
            className={styles['create-button']}
            variant="custom"
            onClick={() => {
              navigate('/applications/create');
            }}
            startIcon={<AddIcon className={styles['start-icon-button']} />}
          >
            Создать приложение
          </Button>
        )}
      </Box>
      <Box className={styles['tabs-wrapper']}>
        <Popover
          classes={{ paper: styles['view-popover-paper'] }}
          onClose={() => setViewAnchorEl(null)}
          anchorEl={viewAnchorEl}
          open={!!viewAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <SelectViewType isClientPanelOpen={isClientPanelOpen} setPanelView={setPanelView} />
        </Popover>
        {!isMobile && (
          <Button
            onClick={(event: MouseEvent<HTMLButtonElement>) => setViewAnchorEl(event.currentTarget)}
            className={styles.view}
            classes={{ endIcon: styles['view-icon'] }}
            endIcon={<ArrowDown fill="#9DA2B3" />}
          >
            <Typography className={clsx('text-14', 'color-858BA0')}>Вид</Typography>
          </Button>
        )}
      </Box>
      {isAdministrator(userRole) && (
        <div className={styles['tab-container']}>
          <ApplicationsTable variant="admin" />
          <ApplicationRightPanel variant="admin" />
        </div>
      )}
    </Box>
  );
};

export const Applications = connect(mapStateToProps)(ApplicationsComponent);
