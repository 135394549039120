import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { CLIENT_ID } from '../constants';
import { convertUserProfile } from '../helpers';
import { setUserProfile } from '../redux/userSlice';
// import { RootState } from '../redux/rootReducer';
import { getTokensByCode, sendAuthRequest } from '../requests/oidc';
import { getUserRoleInApp } from '../requests/user';
import { decodeJWT, isTokenValid, setTokenCookies } from '../service/auth';
import { useNavigate } from 'react-router-dom-v5-compat';

type TCodeProps = unknown;
const mapStateToProps = () => ({});

const CodeComponent: React.FC<TCodeProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const auth = async () => {
      try {
        const { accessToken, idToken, refreshToken, expiresIn } = await getTokensByCode();

        if (await isTokenValid(idToken)) {
          const decodedJWT = decodeJWT(idToken);
          if (decodedJWT) {
            setTokenCookies(accessToken, refreshToken, expiresIn);

            const userProfile = convertUserProfile(decodedJWT);
            const role = await getUserRoleInApp(userProfile.id || '', CLIENT_ID);

            dispatch(setUserProfile({ ...userProfile, role }));
          }
          navigate('/main');
        } else {
          sendAuthRequest('consent');
        }
      } catch (e) {
        console.log('auth error: ' + e);
        navigate('/login');
      }
    };

    auth();
  }, []);

  return <LinearProgress />;
};

export const Code = connect(mapStateToProps)(CodeComponent);
