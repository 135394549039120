import React, { FC, useEffect, useState } from 'react';
import styles from './UserEvent.module.css';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useNavigate, useParams } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { TLogger, useLazyGetUserActivityQuery } from '../../redux/services/logger';
import { format } from 'date-fns';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';

const UserEvent: FC = () => {
  const navigate = useNavigate();
  const { eventId } = useParams<{ eventId: string }>();
  const userId = useSelector((state: RootState) => state.user.userProfile.id);
  const [getUserActivity] = useLazyGetUserActivityQuery();
  const [selectedEvent, setSelectedEvent] = useState<Partial<TLogger> | null>(null);

  useEffect(() => {
    const getEvent = async () => {
      if (eventId && userId) {
        const { data: events } = await getUserActivity({
          user_id: userId,
          log_type: 'user',
          search_string: eventId,
        });

        if (events?.length) setSelectedEvent(events[0]);
      }
    };
    getEvent();
  }, [eventId, userId]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Button
          onClick={() => navigate('/profile/activity')}
          variant="custom3"
          className={clsx('text-15', styles['button-back'])}
          startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
        >
          Журнал
        </Button>
        <Typography
          style={{ marginBottom: 32 }}
          className={clsx('text-24-medium', 'font-golos', 'color-0B1641')}
        >
          Информация о событии
        </Typography>
        <Box className={styles.panel}>
          <Typography className={clsx('header-3', 'font-golos', styles['title'])}>
            {!selectedEvent ? (
              <Skeleton variant="text" width="20%" />
            ) : (
              `Вход в ${selectedEvent?.name}`
            )}
          </Typography>
          <div className={styles.info}>
            <div className={styles['info-item']}>
              <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                Дата
              </Typography>
              <div className={styles['value-wrap']}>
                {!selectedEvent?.date ? (
                  <Skeleton variant="text" width="50%" />
                ) : (
                  <Typography
                    className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                  >
                    {format(new Date(selectedEvent.date), 'y-MM-dd HH:mm:ss')}
                  </Typography>
                )}
              </div>
            </div>
            <div className={styles['info-item']}>
              <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                Местоположение
              </Typography>
              <div className={styles['value-wrap']}>
                {!selectedEvent ? (
                  <Skeleton variant="text" width="50%" />
                ) : (
                  <Typography
                    className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                  >
                    {selectedEvent?.ip_address?.replace('::ffff:', '')}
                  </Typography>
                )}
              </div>
            </div>
            <div className={styles['info-item']}>
              <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                Устройство
              </Typography>
              <div className={styles['value-wrap']}>
                {!selectedEvent ? (
                  <Skeleton variant="text" width="50%" />
                ) : (
                  <Typography
                    className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                  >
                    {selectedEvent?.device}
                  </Typography>
                )}
              </div>
            </div>
            <div className={styles['info-item']}>
              <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                Описание
              </Typography>
              <div className={styles['value-wrap']}>
                {!selectedEvent ? (
                  <Skeleton variant="text" width="50%" />
                ) : (
                  <Typography
                    className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}
                  >
                    {selectedEvent?.description}
                  </Typography>
                )}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default UserEvent;
